import { ComponentProps, FunctionComponent, PropsWithChildren } from "react";

import {
  apolloClient as ApolloClient,
  ApolloClientOptions,
} from "../data-fetching/providers/apollo";

import { default as CaptchaProvider } from "./CaptchaProvider";

const withCaptchaProviderSSR = <P extends object>(
  Wrapped: (props: P) => JSX.Element | null
): FunctionComponent<
  PropsWithChildren<
    P &
      Omit<
        ComponentProps<typeof CaptchaProvider>,
        "apolloClient" | "loadingByDefault"
      > &
      Pick<ApolloClientOptions, "apiOrigin" | "clientKey">
  >
> => {
  return (props) => {
    const {
      apiOrigin,
      appId,
      appScheme,
      children,
      clientKey,
      loadingComponent,
      renderError,
    } = props;

    const apolloClient = ApolloClient.getNew({
      apiOrigin,
      apiURLSignatureEnabled: true,
      appId,
      clientKey,
      forceRefresh: () => {},
      logError: () => {
        // TODO: log error
      },
      signOutURL: null,
    });

    return (
      <CaptchaProvider
        apolloClient={apolloClient}
        appId={appId}
        appScheme={appScheme}
        loadingByDefault={false}
        loadingComponent={loadingComponent}
        renderError={renderError}
      >
        <Wrapped {...(props as P)}>{children}</Wrapped>
      </CaptchaProvider>
    );
  };
};

export default withCaptchaProviderSSR;
