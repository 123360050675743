import AsyncStorage from "@react-native-async-storage/async-storage";

/**
 * @deprecated Legacy storage management entries, re-mapped for backward compatibility.
 * Please import and use `AsyncStorage` only.
 */
const storage = {
  get: AsyncStorage.getItem,
  getKeys: AsyncStorage.getAllKeys,
  remove: AsyncStorage.removeItem,
  removeMultiple: AsyncStorage.multiRemove,
  set: AsyncStorage.setItem,
  setMultiple: AsyncStorage.multiSet,
};

export { AsyncStorage, storage };
