const API_ALLOW_GUEST_ACCESS = process.env.NEXT_PUBLIC_API_ALLOW_GUEST_ACCESS;
const API_DEFAULT_BOT_TOKEN = process.env.API_DEFAULT_BOT_TOKEN;
const API_DEFAULT_USER_TOKEN = process.env.API_DEFAULT_USER_TOKEN;
const API_KEY = process.env.NEXT_PUBLIC_API_KEY;
const API_ORIGIN = process.env.NEXT_PUBLIC_API_ORIGIN;

const APP_SCHEME = process.env.NEXT_PUBLIC_APP_SCHEME;

const AUTH_CAPTCHA_URL = process.env.NEXT_PUBLIC_CAPTCHA_URL;
const AUTH_REACTIVATE_URL = process.env.NEXT_PUBLIC_AUTH_REACTIVATE_URL;
const AUTH_SHARED_SIGN_IN_ENABLED =
  process.env.NEXT_PUBLIC_AUTH_SHARED_SIGN_IN_ENABLED;
const AUTH_SIGN_IN_URL = process.env.NEXT_PUBLIC_AUTH_SIGN_IN_URL;
const AUTH_SIGN_OUT_URL = process.env.NEXT_PUBLIC_AUTH_SIGN_OUT_URL;
const AUTH_TOKEN_MIGRATE_URL = process.env.NEXT_PUBLIC_AUTH_TOKEN_MIGRATE_URL;

const CUSTOMER_IO_CDP_API_KEY = process.env.NEXT_PUBLIC_CUSTOMER_IO_CDP_API_KEY;
const CUSTOMER_IO_SITE_KEY = process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_KEY;

const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
const FIREBASE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;
const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET_ID =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_ID;

const FIREBASE_CROSS_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_CROSS_API_KEY;
const FIREBASE_CROSS_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_CROSS_APP_ID;
const FIREBASE_CROSS_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_AUTH_DOMAIN;
const FIREBASE_CROSS_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_MEASUREMENT_ID;
const FIREBASE_CROSS_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_MESSAGING_SENDER_ID;
const FIREBASE_CROSS_PROJECT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_PROJECT_ID;
const FIREBASE_CROSS_STORAGE_BUCKET_ID =
  process.env.NEXT_PUBLIC_FIREBASE_CROSS_STORAGE_BUCKET_ID;

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
const MIXPANEL_PRO_PROJECT_TOKEN =
  process.env.NEXT_PUBLIC_MIXPANEL_PRO_PROJECT_TOKEN;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
const ACCOUNT_URL = process.env.NEXT_PUBLIC_ACCOUNT_URL;
const COM_URL = process.env.NEXT_PUBLIC_COM_URL;
const CURATOR_URL = process.env.NEXT_PUBLIC_CURATOR_URL;
const PUBLISHER_URL = process.env.NEXT_PUBLIC_PUBLISHER_URL;

const DATO_CMS_API_TOKEN = process.env.NEXT_PUBLIC_DATO_CMS_API_TOKEN;

const VIDEO_LYRICS_EMBEDDED = process.env.NEXT_PUBLIC_VIDEO_LYRICS_EMBEDDED;
const VIDEO_LYRICS_URL = process.env.NEXT_PUBLIC_VIDEO_LYRICS_URL;

module.exports = {
  ACCOUNT_URL,
  API_ALLOW_GUEST_ACCESS,
  API_DEFAULT_BOT_TOKEN,
  API_DEFAULT_USER_TOKEN,
  API_KEY,
  API_ORIGIN,
  APP_SCHEME,
  APP_URL,
  AUTH_CAPTCHA_URL,
  AUTH_REACTIVATE_URL,
  AUTH_SHARED_SIGN_IN_ENABLED,
  AUTH_SIGN_IN_URL,
  AUTH_SIGN_OUT_URL,
  AUTH_TOKEN_MIGRATE_URL,
  COM_URL,
  CURATOR_URL,
  CUSTOMER_IO_CDP_API_KEY,
  CUSTOMER_IO_SITE_KEY,
  DATO_CMS_API_TOKEN,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_CROSS_API_KEY,
  FIREBASE_CROSS_APP_ID,
  FIREBASE_CROSS_AUTH_DOMAIN,
  FIREBASE_CROSS_MEASUREMENT_ID,
  FIREBASE_CROSS_MESSAGING_SENDER_ID,
  FIREBASE_CROSS_PROJECT_ID,
  FIREBASE_CROSS_STORAGE_BUCKET_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  INTERCOM_APP_ID,
  MIXPANEL_PRO_PROJECT_TOKEN,
  PUBLISHER_URL,
  SENTRY_DSN,
  VIDEO_LYRICS_EMBEDDED,
  VIDEO_LYRICS_URL,
};
