import {
  ApolloError,
  ApolloClient,
  ApolloQueryResult,
  Context,
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  MutationHookOptions,
  MutationTuple,
  NetworkStatus,
  ObservableQuery,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  useApolloClient,
  useLazyQuery as useNotImmediateQuery,
  useMutation as useApolloMutation,
  useQuery as useImmediateQuery,
  TypedDocumentNode,
} from "@apollo/client";
import { useCallback, useMemo } from "react";

import { useAuth as useAuthSSR } from "../auth-ssr/client";
import { useCaptchaId } from "../captcha";

export const CONTEXT_ENDPOINT_API2 = "api2";
export const CONTEXT_ENDPOINT_ATLANTIS = "atlantis";
export const CONTEXT_ENDPOINT_DATO = "datocms";
export const CONTEXT_ENDPOINT_DATOCMS_NEXT = "datocms-next";
export const CONTEXT_ENDPOINT_DATOCMS_PROXY = "datocms-proxy";
export const CONTEXT_ENDPOINT_FILE_UPLOAD = "fileupload";
export const CONTEXT_ENDPOINT_QUEUE_SENDER = "queue-sender";
export const CONTEXT_ENDPOINT_APP_PROXY = "app-proxy";
export const binaryToBoolean = (value?: string | number) => {
  if (value === undefined) {
    return false;
  }

  return Boolean(Number(value));
};

export const booleanToBinary = (condition: boolean | undefined) =>
  condition ? "1" : "0";

type GQLAuthVars = { appId?: string; userToken?: string };
type RestAuthVars = { app_id?: string; usertoken?: string };
type AuthVars = GQLAuthVars & RestAuthVars;

type Options<TData, TVariables> =
  | LazyQueryHookOptions<TData, TVariables & AuthVars>
  | LazyQueryResult<TData, TVariables & AuthVars>
  | MutationHookOptions<TData, TVariables & AuthVars>
  | QueryHookOptions<TData, TVariables & AuthVars>;

export const useAuth = (): {
  appId?: string;
  captchaId?: string | null;
  userToken?: string;
} | null => {
  const authSSR = useAuthSSR();
  const captchaId = useCaptchaId();

  return useMemo(() => {
    if (authSSR.isLoggedIn) {
      return {
        appId: authSSR.appId,
        captchaId,
        userToken: authSSR.userToken ?? undefined,
      };
    }

    return {
      appId: authSSR.appId,
      captchaId,
    };
  }, [authSSR.appId, authSSR.isLoggedIn, authSSR.userToken, captchaId]);
};

const getOptions = <TData, TVariables>(
  options?: Options<TData, TVariables>,
  appId?: string,
  userToken?: string,
  captchaId?: string | null
): Options<TData, TVariables> => {
  let variables = {
    ...options?.variables,
    ...(captchaId && { captcha_id: captchaId }),
  };

  let context =
    options && "context" in options && options?.context ? options.context : {};

  if (
    context.endpoint === CONTEXT_ENDPOINT_DATO ||
    context.endpoint === CONTEXT_ENDPOINT_DATOCMS_NEXT
  ) {
    // DatoCMS doesn't need app_id or anything else
    return options ?? {};
  }

  if (
    context.endpoint === CONTEXT_ENDPOINT_ATLANTIS ||
    context.endpoint === CONTEXT_ENDPOINT_API2 ||
    context.endpoint === CONTEXT_ENDPOINT_FILE_UPLOAD ||
    context.endpoint === CONTEXT_ENDPOINT_DATOCMS_PROXY
  ) {
    context = {
      ...context,
      params: {
        app_id: appId,
        usertoken: userToken,
      },
    };
  } else if (
    options?.variables?.appId === null &&
    options?.variables?.userToken === null
  ) {
    // GraphQL
    variables = {
      ...variables,
      appId,
      userToken,
    };
  } else {
    // REST
    variables = {
      ...variables,
      app_id: appId,
      usertoken: userToken,
    };
  }

  return {
    ...options,
    context,
    variables,
  } as Options<TData, TVariables>;
};

export const isResponseOk = (
  statusCode: number | undefined,
  isSSR = false
): boolean => {
  if (isSSR) {
    return true;
  }

  if (statusCode === undefined) {
    return false;
  }

  return /^2\d\d$/.test(statusCode?.toString());
};

export type EmptyVariables = object;

export const createCustomQuerySet = (endpoint: string) => {
  const useCustomQuery = <
    D = any,
    V extends OperationVariables = OperationVariables
  >(
    query: DocumentNode | TypedDocumentNode<D, V>,
    options?: QueryHookOptions<D, V>
  ) =>
    useQuery(query, { ...options, context: { ...options?.context, endpoint } });

  const useCustomMutation = <
    D = any,
    V extends OperationVariables = OperationVariables
  >(
    query: DocumentNode | TypedDocumentNode<D, V>,
    options?: MutationHookOptions<D, V>
  ) =>
    useMutation(query, {
      ...options,
      context: { ...options?.context, endpoint },
    });

  const useCustomLazyQuery = <
    D = any,
    V extends OperationVariables = OperationVariables
  >(
    query: DocumentNode | TypedDocumentNode<D, V>,
    options?: LazyQueryHookOptions<D, V>
  ) =>
    useLazyQuery(query, {
      ...options,
      context: { ...options?.context, endpoint },
    });

  return [useCustomQuery, useCustomMutation, useCustomLazyQuery] as const;
};

export const useLazyQuery = <
  TData extends any,
  TVariables extends OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  const auth = useAuth();

  return useNotImmediateQuery(
    query,
    getOptions<TData, TVariables>(
      options,
      auth?.appId,
      auth?.userToken,
      auth?.captchaId
    )
  );
};

export const useMutation = <
  TData extends any,
  TVariables extends OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
  const auth = useAuth();

  return useApolloMutation(
    query,
    getOptions<TData, TVariables>(
      options,
      auth?.appId,
      auth?.userToken,
      auth?.captchaId
    ) as MutationHookOptions<TData, TVariables>
  );
};

export const useQuery = <
  TData extends any,
  TVariables extends OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const auth = useAuth();

  return useImmediateQuery(
    query,
    getOptions<TData, TVariables>(
      options,
      auth?.appId,
      auth?.userToken,
      auth?.captchaId
    )
  );
};

export const [useApi2Query, useApi2Mutation, useApi2LazyQuery] =
  createCustomQuerySet(CONTEXT_ENDPOINT_API2);

export const [useFileUploadQuery, , useFileUploadLazyQuery] =
  createCustomQuerySet(CONTEXT_ENDPOINT_FILE_UPLOAD);

export const [useAtlantisQuery, useAtlantisMutation, useAtlantisLazyQuery] =
  createCustomQuerySet(CONTEXT_ENDPOINT_ATLANTIS);

export const [useDatoQuery, , useDatoLazyQuery] = createCustomQuerySet(
  CONTEXT_ENDPOINT_DATO
);
export const [useDatoNextQuery, , useDatoNextLazyQuery] = createCustomQuerySet(
  CONTEXT_ENDPOINT_DATOCMS_NEXT
);
export const [useDatoProxyQuery, , useDatoProxyLazyQuery] =
  createCustomQuerySet(CONTEXT_ENDPOINT_DATOCMS_PROXY);
export const [useAppProxyQuery, useAppProxyMutation, useAppProxyLazyQuery] =
  createCustomQuerySet(CONTEXT_ENDPOINT_APP_PROXY);

/*
  useClientQuery and useAtlantisClientQuery should be used only 
  when it's mandatory for the developer to get the results 
  of a query directly from the promise for some reason
  (e.g. when populating the autocomplete component's result)
*/
export const useClientQuery = <
  TData extends any,
  TVariables extends OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
): {
  client: ApolloClient<unknown>;
  query: (
    additionalVariables?: TVariables
  ) => Promise<ApolloQueryResult<TData>>;
} => {
  const client: ApolloClient<unknown> = useApolloClient();
  const auth = useAuth();

  return {
    client,
    query: useCallback(
      (additionalVariables?: TVariables) =>
        client.query({
          query,
          variables: {
            ...variables,
            app_id: auth?.appId,
            usertoken: auth?.userToken,
            ...additionalVariables,
          } as unknown as TVariables,
        }),
      [auth?.appId, auth?.userToken, client, query, variables]
    ),
  };
};

export const useAtlantisClientQuery = <
  TData extends any,
  TVariables extends OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: QueryHookOptions<TData, TVariables>
): {
  client: ApolloClient<unknown>;
  query: (
    additionalVariables?: TVariables
  ) => Promise<ApolloQueryResult<TData>>;
} => {
  const client: ApolloClient<unknown> = useApolloClient();
  const auth = useAuth();

  const editedOptions = getOptions(
    {
      context: {
        endpoint: CONTEXT_ENDPOINT_ATLANTIS,
      },
    },
    auth?.appId,
    auth?.userToken,
    auth?.captchaId
  );

  return {
    client,
    query: useCallback(
      (additionalVariables?: TVariables) =>
        client.query({
          context: (editedOptions as { context: Context }).context,
          query,
          variables: {
            ...variables,
            ...additionalVariables,
          } as TVariables,
        }),
      [client, query, editedOptions, variables]
    ),
  };
};

export const useDataFetchingClient = (): ApolloClient<unknown> =>
  useApolloClient();

export const formDataSerializer = (data: any, headers: Headers) => {
  const formData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(
        key,
        data[key] instanceof File ? data[key] : JSON.stringify(data[key])
      );
    }
  }

  return { body: formData, headers };
};

export { ApolloClient, ApolloError, NetworkStatus, ObservableQuery };
