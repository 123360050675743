import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";

import {
  CONTEXT_ENDPOINT_ATLANTIS,
  CONTEXT_ENDPOINT_DATO,
  CONTEXT_ENDPOINT_FILE_UPLOAD,
  CONTEXT_ENDPOINT_API2,
  CONTEXT_ENDPOINT_DATOCMS_NEXT,
  CONTEXT_ENDPOINT_QUEUE_SENDER,
  CONTEXT_ENDPOINT_DATOCMS_PROXY,
  CONTEXT_ENDPOINT_APP_PROXY,
} from "../../dataFetchingUtils";

import errorLink from "./apolloErrorLink";
import {
  datoLink,
  atlantisLink,
  api2Link,
  fileUploadLink,
  datoNextLink,
  queueSenderLink,
  datoProxyLink,
} from "./apolloHttpLink";
import restLink, { appProxyRestLink } from "./apolloRestLink";
import { ApolloClientOptions } from "./types";

export const splitterLink = (options: ApolloClientOptions) =>
  new ApolloLink((op, forward) => {
    const { endpoint } = op.getContext();

    switch (endpoint) {
      case CONTEXT_ENDPOINT_API2:
        return api2Link(options).request(op);
      case CONTEXT_ENDPOINT_ATLANTIS:
        return atlantisLink(options).request(op);
      case CONTEXT_ENDPOINT_DATO:
        return datoLink(options).request(op);
      case CONTEXT_ENDPOINT_DATOCMS_NEXT:
        return datoNextLink(options).request(op);
      case CONTEXT_ENDPOINT_DATOCMS_PROXY:
        return datoProxyLink(options).request(op);
      case CONTEXT_ENDPOINT_FILE_UPLOAD:
        return fileUploadLink(options).request(op);
      case CONTEXT_ENDPOINT_QUEUE_SENDER:
        return queueSenderLink(options).request(op);
      case CONTEXT_ENDPOINT_APP_PROXY:
        return appProxyRestLink(options).request(op);
      default:
        return forward(op);
    }
  });

export const appVersionLink = (options: ApolloClientOptions) =>
  new ApolloLink((op, forward) => {
    const { endpoint } = op.getContext();

    switch (endpoint) {
      // DatoCMS's CORS configuration policy doesn't support custom headers and will error out upon receiving one
      case CONTEXT_ENDPOINT_DATO:
      case CONTEXT_ENDPOINT_DATOCMS_NEXT:
        return forward(op);
    }

    op.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "X-Mxm-App-Version": options.appVersion,
      },
    }));

    return forward(op);
  });

const apolloClient = (() => {
  let client: ApolloClient<unknown>;

  const init = (options: ApolloClientOptions) => {
    return new ApolloClient({
      cache: new InMemoryCache({
        resultCacheMaxSize: 1000,
      }),
      connectToDevTools: __DEV__,
      link: from([
        appVersionLink(options),
        errorLink(options),
        splitterLink(options),
        restLink(options),
      ]),
      ssrMode: options.ssrMode ?? true,
    });
  };

  return {
    get: (options: ApolloClientOptions) => {
      if (client === undefined) {
        client = init(options);
      }

      return client;
    },

    getNew: (options: ApolloClientOptions) => {
      return init(options);
    },
  };
})();

export default apolloClient;
