// THIS FILE IS AUTOGENERATED. DO NOT CHANGE.

// @ts-nocheck
import { getI18n, Trans, useTranslation } from "react-i18next";

import { DefaultCustomTypeOptions, initI18next, InitI18nParams } from "./src";
import arCommon from "./translations/ar-SA/common.json";
import arLanguages from "./translations/ar-SA/languages.json";
import arPro from "./translations/ar-SA/pro.json";
import arProShared from "./translations/ar-SA/pro_shared.json";
import arVlyrics from "./translations/ar-SA/vlyrics.json";
import bnCommon from "./translations/bn-BD/common.json";
import bnLanguages from "./translations/bn-BD/languages.json";
import bnPro from "./translations/bn-BD/pro.json";
import bnProShared from "./translations/bn-BD/pro_shared.json";
import bnVlyrics from "./translations/bn-BD/vlyrics.json";
import csCommon from "./translations/cs-CZ/common.json";
import csLanguages from "./translations/cs-CZ/languages.json";
import csPro from "./translations/cs-CZ/pro.json";
import csProShared from "./translations/cs-CZ/pro_shared.json";
import csVlyrics from "./translations/cs-CZ/vlyrics.json";
import daCommon from "./translations/da-DK/common.json";
import daLanguages from "./translations/da-DK/languages.json";
import daPro from "./translations/da-DK/pro.json";
import daProShared from "./translations/da-DK/pro_shared.json";
import daVlyrics from "./translations/da-DK/vlyrics.json";
import deCommon from "./translations/de-DE/common.json";
import deLanguages from "./translations/de-DE/languages.json";
import dePro from "./translations/de-DE/pro.json";
import deProShared from "./translations/de-DE/pro_shared.json";
import deVlyrics from "./translations/de-DE/vlyrics.json";
import enCommon from "./translations/en-US/common.json";
import enLanguages from "./translations/en-US/languages.json";
import enPro from "./translations/en-US/pro.json";
import enProShared from "./translations/en-US/pro_shared.json";
import enVlyrics from "./translations/en-US/vlyrics.json";
import esCommon from "./translations/es-ES/common.json";
import esLanguages from "./translations/es-ES/languages.json";
import esPro from "./translations/es-ES/pro.json";
import esProShared from "./translations/es-ES/pro_shared.json";
import esVlyrics from "./translations/es-ES/vlyrics.json";
import faCommon from "./translations/fa-IR/common.json";
import faLanguages from "./translations/fa-IR/languages.json";
import faPro from "./translations/fa-IR/pro.json";
import faProShared from "./translations/fa-IR/pro_shared.json";
import faVlyrics from "./translations/fa-IR/vlyrics.json";
import frCommon from "./translations/fr-FR/common.json";
import frLanguages from "./translations/fr-FR/languages.json";
import frPro from "./translations/fr-FR/pro.json";
import frProShared from "./translations/fr-FR/pro_shared.json";
import frVlyrics from "./translations/fr-FR/vlyrics.json";
import hiCommon from "./translations/hi-IN/common.json";
import hiLanguages from "./translations/hi-IN/languages.json";
import hiPro from "./translations/hi-IN/pro.json";
import hiProShared from "./translations/hi-IN/pro_shared.json";
import hiVlyrics from "./translations/hi-IN/vlyrics.json";
import idCommon from "./translations/id-ID/common.json";
import idLanguages from "./translations/id-ID/languages.json";
import idPro from "./translations/id-ID/pro.json";
import idProShared from "./translations/id-ID/pro_shared.json";
import idVlyrics from "./translations/id-ID/vlyrics.json";
import itCommon from "./translations/it-IT/common.json";
import itLanguages from "./translations/it-IT/languages.json";
import itPro from "./translations/it-IT/pro.json";
import itProShared from "./translations/it-IT/pro_shared.json";
import itVlyrics from "./translations/it-IT/vlyrics.json";
import jaCommon from "./translations/ja-JP/common.json";
import jaLanguages from "./translations/ja-JP/languages.json";
import jaPro from "./translations/ja-JP/pro.json";
import jaProShared from "./translations/ja-JP/pro_shared.json";
import jaVlyrics from "./translations/ja-JP/vlyrics.json";
import koCommon from "./translations/ko-KR/common.json";
import koLanguages from "./translations/ko-KR/languages.json";
import koPro from "./translations/ko-KR/pro.json";
import koProShared from "./translations/ko-KR/pro_shared.json";
import koVlyrics from "./translations/ko-KR/vlyrics.json";
import nlCommon from "./translations/nl-NL/common.json";
import nlLanguages from "./translations/nl-NL/languages.json";
import nlPro from "./translations/nl-NL/pro.json";
import nlProShared from "./translations/nl-NL/pro_shared.json";
import nlVlyrics from "./translations/nl-NL/vlyrics.json";
import plCommon from "./translations/pl-PL/common.json";
import plLanguages from "./translations/pl-PL/languages.json";
import plPro from "./translations/pl-PL/pro.json";
import plProShared from "./translations/pl-PL/pro_shared.json";
import plVlyrics from "./translations/pl-PL/vlyrics.json";
import ptCommon from "./translations/pt-BR/common.json";
import ptLanguages from "./translations/pt-BR/languages.json";
import ptPro from "./translations/pt-BR/pro.json";
import ptProShared from "./translations/pt-BR/pro_shared.json";
import ptVlyrics from "./translations/pt-BR/vlyrics.json";
import roCommon from "./translations/ro-RO/common.json";
import roLanguages from "./translations/ro-RO/languages.json";
import roPro from "./translations/ro-RO/pro.json";
import roProShared from "./translations/ro-RO/pro_shared.json";
import roVlyrics from "./translations/ro-RO/vlyrics.json";
import ruCommon from "./translations/ru-RU/common.json";
import ruLanguages from "./translations/ru-RU/languages.json";
import ruPro from "./translations/ru-RU/pro.json";
import ruProShared from "./translations/ru-RU/pro_shared.json";
import ruVlyrics from "./translations/ru-RU/vlyrics.json";
import svCommon from "./translations/sv-SE/common.json";
import svLanguages from "./translations/sv-SE/languages.json";
import svPro from "./translations/sv-SE/pro.json";
import svProShared from "./translations/sv-SE/pro_shared.json";
import svVlyrics from "./translations/sv-SE/vlyrics.json";
import taCommon from "./translations/ta-IN/common.json";
import taLanguages from "./translations/ta-IN/languages.json";
import taPro from "./translations/ta-IN/pro.json";
import taProShared from "./translations/ta-IN/pro_shared.json";
import taVlyrics from "./translations/ta-IN/vlyrics.json";
import thCommon from "./translations/th-TH/common.json";
import thLanguages from "./translations/th-TH/languages.json";
import thPro from "./translations/th-TH/pro.json";
import thProShared from "./translations/th-TH/pro_shared.json";
import thVlyrics from "./translations/th-TH/vlyrics.json";
import tlCommon from "./translations/tl-PH/common.json";
import tlLanguages from "./translations/tl-PH/languages.json";
import tlPro from "./translations/tl-PH/pro.json";
import tlProShared from "./translations/tl-PH/pro_shared.json";
import tlVlyrics from "./translations/tl-PH/vlyrics.json";
import trCommon from "./translations/tr-TR/common.json";
import trLanguages from "./translations/tr-TR/languages.json";
import trPro from "./translations/tr-TR/pro.json";
import trProShared from "./translations/tr-TR/pro_shared.json";
import trVlyrics from "./translations/tr-TR/vlyrics.json";
import viCommon from "./translations/vi-VN/common.json";
import viLanguages from "./translations/vi-VN/languages.json";
import viPro from "./translations/vi-VN/pro.json";
import viProShared from "./translations/vi-VN/pro_shared.json";
import viVlyrics from "./translations/vi-VN/vlyrics.json";
import zhCommon from "./translations/zh-TW/common.json";
import zhLanguages from "./translations/zh-TW/languages.json";
import zhPro from "./translations/zh-TW/pro.json";
import zhProShared from "./translations/zh-TW/pro_shared.json";
import zhVlyrics from "./translations/zh-TW/vlyrics.json";

const resources = {
  ar: {
    common: arCommon,
    languages: arLanguages,
    pro: arPro,
    pro_shared: arProShared,
    vlyrics: arVlyrics,
  },
  bn: {
    common: bnCommon,
    languages: bnLanguages,
    pro: bnPro,
    pro_shared: bnProShared,
    vlyrics: bnVlyrics,
  },
  cs: {
    common: csCommon,
    languages: csLanguages,
    pro: csPro,
    pro_shared: csProShared,
    vlyrics: csVlyrics,
  },
  da: {
    common: daCommon,
    languages: daLanguages,
    pro: daPro,
    pro_shared: daProShared,
    vlyrics: daVlyrics,
  },
  de: {
    common: deCommon,
    languages: deLanguages,
    pro: dePro,
    pro_shared: deProShared,
    vlyrics: deVlyrics,
  },
  en: {
    common: enCommon,
    languages: enLanguages,
    pro: enPro,
    pro_shared: enProShared,
    vlyrics: enVlyrics,
  },
  es: {
    common: esCommon,
    languages: esLanguages,
    pro: esPro,
    pro_shared: esProShared,
    vlyrics: esVlyrics,
  },
  fa: {
    common: faCommon,
    languages: faLanguages,
    pro: faPro,
    pro_shared: faProShared,
    vlyrics: faVlyrics,
  },
  fr: {
    common: frCommon,
    languages: frLanguages,
    pro: frPro,
    pro_shared: frProShared,
    vlyrics: frVlyrics,
  },
  hi: {
    common: hiCommon,
    languages: hiLanguages,
    pro: hiPro,
    pro_shared: hiProShared,
    vlyrics: hiVlyrics,
  },
  id: {
    common: idCommon,
    languages: idLanguages,
    pro: idPro,
    pro_shared: idProShared,
    vlyrics: idVlyrics,
  },
  it: {
    common: itCommon,
    languages: itLanguages,
    pro: itPro,
    pro_shared: itProShared,
    vlyrics: itVlyrics,
  },
  ja: {
    common: jaCommon,
    languages: jaLanguages,
    pro: jaPro,
    pro_shared: jaProShared,
    vlyrics: jaVlyrics,
  },
  ko: {
    common: koCommon,
    languages: koLanguages,
    pro: koPro,
    pro_shared: koProShared,
    vlyrics: koVlyrics,
  },
  nl: {
    common: nlCommon,
    languages: nlLanguages,
    pro: nlPro,
    pro_shared: nlProShared,
    vlyrics: nlVlyrics,
  },
  pl: {
    common: plCommon,
    languages: plLanguages,
    pro: plPro,
    pro_shared: plProShared,
    vlyrics: plVlyrics,
  },
  pt: {
    common: ptCommon,
    languages: ptLanguages,
    pro: ptPro,
    pro_shared: ptProShared,
    vlyrics: ptVlyrics,
  },
  ro: {
    common: roCommon,
    languages: roLanguages,
    pro: roPro,
    pro_shared: roProShared,
    vlyrics: roVlyrics,
  },
  ru: {
    common: ruCommon,
    languages: ruLanguages,
    pro: ruPro,
    pro_shared: ruProShared,
    vlyrics: ruVlyrics,
  },
  sv: {
    common: svCommon,
    languages: svLanguages,
    pro: svPro,
    pro_shared: svProShared,
    vlyrics: svVlyrics,
  },
  ta: {
    common: taCommon,
    languages: taLanguages,
    pro: taPro,
    pro_shared: taProShared,
    vlyrics: taVlyrics,
  },
  th: {
    common: thCommon,
    languages: thLanguages,
    pro: thPro,
    pro_shared: thProShared,
    vlyrics: thVlyrics,
  },
  tl: {
    common: tlCommon,
    languages: tlLanguages,
    pro: tlPro,
    pro_shared: tlProShared,
    vlyrics: tlVlyrics,
  },
  tr: {
    common: trCommon,
    languages: trLanguages,
    pro: trPro,
    pro_shared: trProShared,
    vlyrics: trVlyrics,
  },
  vi: {
    common: viCommon,
    languages: viLanguages,
    pro: viPro,
    pro_shared: viProShared,
    vlyrics: viVlyrics,
  },
  zh: {
    common: zhCommon,
    languages: zhLanguages,
    pro: zhPro,
    pro_shared: zhProShared,
    vlyrics: zhVlyrics,
  },
} as const;

declare module "i18next" {
  interface CustomTypeOptions extends DefaultCustomTypeOptions {
    defaultNS: ["common", "languages", "pro_shared", "pro", "vlyrics"];
    resources: (typeof resources)["en"];
  }
}

const initI18n = (args: InitI18nParams) =>
  initI18next({ localResources: resources, ...args });

export { getI18n, initI18n, Trans, useTranslation };
